<template>
  <v-row class="mx-0">
    <v-col cols="3">
      <v-sheet class="pt-4" color="transparent"><!-- style="position:sticky; top:100px" -->
        <v-card width="180px">
          <v-card-text>
            <v-responsive class="align-center text-center" :aspect-ratio="1/1">
              <img :src="require(`@/assets/appAsset-brand--${activeBiller.brand}.svg`)" :alt="activeBiller.title" width="90%">
            </v-responsive>
          </v-card-text>
        </v-card>
        <v-list class="pl-0" color="transparent">
          <v-list-item class="pl-0">
            <v-list-item-content class="d-block text--secondary font-weight-medium">Compatibilidad</v-list-item-content>
          </v-list-item>
          <v-list-item class="pl-0">
            <v-list-item-content>
              <v-list-item-title class="text--secondary">Países</v-list-item-title>
              <v-list-item-subtitle class="d-block pt-1 caption text--primary">
                <img class="mr-2" v-for="d in activeBiller.countries" :key="d" :src="require(`@/assets/appAsset-flag--${d}.svg`)" :alt="activeBiller.title" height="16px">
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="pl-0">
            <v-list-item-content>
              <v-list-item-title class="text--secondary">Tipos de facturas</v-list-item-title>
              <v-list-item-subtitle class="pt-1">
                <span class="d-block mb-1 caption text--primary" v-for="d in activeBiller.documentsTypes" :key="d">{{d}}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="pl-0" v-if="activeBiller.otherTypes && activeBiller.otherTypes.length">
            <v-list-item-content>
              <v-list-item-title class="text--secondary">Otros tipos de documentos</v-list-item-title>
              <v-list-item-subtitle class="pt-1">
                <span class="d-block mb-1 caption text--primary" v-for="d in activeBiller.otherTypes" :key="d">{{d}}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-col>

    <v-col cols="9">
      <v-row no-gutters>
        <v-col md="3">
          <span class="d-block caption">Documentación</span>
          <v-btn :href="`${$DOCS_BASE}${activeBiller.docs}`" target="_blank" color="primary" text :ripple="false">Ir a la documentación<v-icon right small>mdi-open-in-new</v-icon></v-btn>
        </v-col>
        <v-divider vertical />
        <v-col md="3" class="ml-2">
          <span class="d-block caption">Sitio web</span>
          <v-btn :href="activeBiller.website" target="_blank" color="primary" text :ripple="false">Visitar el sitio<v-icon right small>mdi-open-in-new</v-icon></v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card>
            <v-toolbar color="transparent" flat height="55">
              <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">
                <template v-if="instance.id">Actualizar conexión</template><template v-else>Conectar</template> {{activeBiller.title}}
              </v-toolbar-title>
            </v-toolbar>
            <v-divider />
            <v-card-text class="pa-0">
              <v-list class="py-5" color="background" dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="ml-1 text--secondary subtitle-2 font-weight-medium">{{activeBiller.apiKeyLabel}}</v-list-item-title>
                    <v-list-item-subtitle class="font-weight-regular">
                      <v-col cols="6" class="pa-0">
                        <v-text-field v-model="$v.billerConnection.api_key.$model" :error="$v.billerConnection.api_key.$error" outlined required single-line dense maxlength="254" :placeholder="activeBiller.placeholder" hide-details />
                      </v-col>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <!-- extra data defontana connection -->
                <template v-if="activeBiller.id === 'defontana'">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="ml-1 text--secondary subtitle-2 font-weight-medium">Contraseña</v-list-item-title>
                      <v-list-item-subtitle class="font-weight-regular">
                        <v-col cols="6" class="pa-0">
                          <v-text-field v-model="$v.billerConnection.extra_data.password.$model" :error="$v.billerConnection.extra_data.password.$error" outlined required single-line dense hide-details type="password" autocomplete="new-password" />
                        </v-col>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                <!-- end extra data defontana connection -->

                <v-list-item class="pt-16" v-if="activeBiller.warning">
                  <v-list-item-content>
                    <v-alert class="mb-0 body-2" dark dense color="primary" border="left" type="info">{{activeBiller.warning}}</v-alert>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-divider />
            <v-card-actions class="pa-4">
              <v-btn v-if="instance.id" @click="destroyBillerConnection" dark color="red">Desconectar</v-btn>
              <v-spacer />
              <v-btn @click="instance.id ? updateBillerConnection() : createBillerConnection()" :loading="loaderConnect" color="primary">
                <template v-if="instance.id">Actualizar conexión</template>
                <template v-else>Conectar</template>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { email, maxLength, required, requiredIf } from 'vuelidate/lib/validators'
import GenericViewMixin from '@/mixins/GenericViewMixin'

import irs from '@/collections/irs'

export default {
  mixins: [
    GenericViewMixin
  ],
  data: () => ({
    irs: irs,
    billerConnection: {
      biller: '',
      api_key: '',
      extra_data: {
        password: null
      }
    },
    loaderConnect: false
  }),
  computed: {
    ...mapState({
      instance: state => state.irs.irsconnectionsList[0] || {}
    }),
    activeBiller () {
      return this.irs.find(b => b.id === this.$route.params.id)
    }
  },
  created () {
    this.billerConnection.biller = this.$route.params.id

    this.$store.dispatch('irs/LIST', {
      resource: 'irsconnections',
      query: {
        biller: this.$route.params.id
      }
    })
  },
  methods: {
    createBillerConnection () {
      this.$v.billerConnection.$touch()

      if (this.$v.billerConnection.$invalid) {
        return false
      }

      this.loaderConnect = true

      this.$store.dispatch('irs/CREATE', {
        resource: 'irsconnections',
        payload: this.billerConnection
      })
      .then(() => {
        this.$dialog.message.info(`Se ha conectado ${this.activeBiller.title}.`)
        this.resetBillerConnection()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.loaderConnect = false
      })
    },
    destroyBillerConnection () {
      this.$dialog.warning({
        title: `Desconectar ${this.activeBiller.title}`,
        text: `
          <span class="d-block mb-4">¿Estás seguro de desconectar este proveedor de emisión?</span>
          <span class="d-block font-weight-medium">Esta acción no se puede deshacer.</span>
        `,
        actions: [
          {
            color: 'red',
            text: 'Continuar',
            handle: () => {
              this.$store.dispatch('irs/DESTROY', {
                resource: 'irsconnections',
                id: this.instance.id
              })
              .then(() => {
                this.$dialog.message.info(`El proveedor de emisión ${this.activeBiller.title} ha sido desconectado.`)
                this.$router.push({ name: 'AccountSettingsDocuments', params: this.$route.params })
              })
              .catch((error) => {
                this.$dialog.message.warning(this.parseErrors(error.response.data))
              })
            }
          }
        ]
      })
    },
    resetBillerConnection () {
      this.billerConnection = {
        biller: this.activeBiller.id,
        api_key: '',
        extra_data: {
          password: null
        }
      }
      this.$v.billerConnection.$reset()
    },
    updateBillerConnection () {
      this.$v.billerConnection.$touch()

      if (this.$v.billerConnection.$invalid) {
        return false
      }

      this.loaderConnect = true

      this.$store.dispatch('irs/UPDATE', {
        resource: 'irsconnections',
        id: this.instance.id,
        payload: this.billerConnection
      })
      .then(() => {
        this.$dialog.message.info(`Se ha actualizado la conexión con ${this.activeBiller.title}.`)
        this.resetBillerConnection()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.loaderConnect = false
      })
    }
  },
  validations: {
    billerConnection: {
      api_key: {
        required,
        maxLength: maxLength(254),
        email: function () {
          return this.activeBiller.id === 'defontana' ? email(this.billerConnection.api_key) : true
        }
      },
      extra_data: {
        password: {
          required: requiredIf(function () {
            return this.activeBiller.id === 'defontana'
          })
        }
      }
    }
  }
}
</script>