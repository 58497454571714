export default [
  {
    id: 'sii',
    brand: 'axteroid',
    countries: ['CL'],
    docs: '/guide/settings/tax-agency/chile-sii',
    routeName: 'IrsBuiltInSettings',
    invoiceTypes: [
      'Factura electrónica',
      'Factura exenta electrónica',
      'Factura electrónica de exportación',
      'Boleta electrónica',
      'Boleta electrónica exenta'
    ],
    otherTypes: [
      'Nota de crédito electrónica',
      'Nota de crédito electrónica de exportación',
      'Nota de débito electrónica',
      'Nota de débito electrónica de exportación'
    ],
    title: 'Facturación incorporada',
    website: 'https://www.axteroid.com'
  },
  {
    id: 'facturapi',
    apiKeyLabel: 'Api Key',
    brand: 'facturapi',
    countries: ['MX'],
    docs: '/guide/settings/documents/billers/facturapi',
    documentsTypes: [
      'Factura de ingreso',
      'Factura de pago'
    ],
    otherTypes: [],
    routeName: 'IrsBiller',
    title: 'Facturapi',
    website: 'https://www.facturapi.io'
  },
  {
    id: 'defontana',
    apiKeyLabel: 'Email',
    brand: 'defontana',
    countries: ['CL'],
    docs: '/guide/settings/documents/billers/defontana',
    documentsTypes: [
      'Factura electrónica',
      'Factura exenta electrónica',
      'Boleta electrónica',
      'Boleta electrónica exenta'
    ],
    otherTypes: [
      'Nota de crédito electrónica',
      'Nota de débito electrónica'
    ],
    routeName: 'IrsBiller',
    title: 'Defontana',
    website: 'https://www.defontana.com'
  },
  {
    id: 'bsale',
    apiKeyLabel: 'Access Token',
    brand: 'bsale',
    countries: ['CL'],
    docs: '/guide/settings/documents/billers/bsale',
    documentsTypes: [
      'Factura electrónica',
      'Factura exenta electrónica',
      'Boleta electrónica',
      'Boleta electrónica exenta'
    ],
    otherTypes: [
      'Nota de crédito electrónica',
      'Nota de débito electrónica'
    ],
    routeName: 'IrsBiller',
    title: 'Bsale',
    website: 'https://www.bsale.cl'
  }
]